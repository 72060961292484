<script setup lang="ts">
withDefaults(
  defineProps<{
    certificateRate: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G'
    certificateKwhep?: number | null
  }>(),
  {
    certificateKwhep: null,
  },
)

const dpeList = [
  { key: 'A', color: 'bg-[#138E3D]' },
  { key: 'B', color: 'bg-[#5CA935]' },
  { key: 'C', color: 'bg-[#CCCF2C]' },
  { key: 'D', color: 'bg-[#FFEA33]' },
  { key: 'E', color: 'bg-[#FDB826]' },
  { key: 'F', color: 'bg-[#EC6717]' },
  { key: 'G', color: 'bg-[#E30019]' },
]
</script>

<template>
  <div>
    <div class="font-bold mb-4">DPE</div>
    <div class="w-min text-center">
      <ul class="flex items-center mb-0">
        <li
          v-for="dpe in dpeList"
          :key="dpe.key"
          :class="[
            'flex items-center justify-center text-gray-700 font-bold',
            dpe.color,
            {
              'w-[44px] h-[44px]': dpe.key === certificateRate,
              'w-[34px] h-[34px]': dpe.key !== certificateRate,
            },
          ]"
          data-testid="transaction-dpe-square"
        >
          <template v-if="dpe.key === certificateRate">
            {{ dpe.key }}
          </template>
        </li>
      </ul>

      <p
        v-if="certificateKwhep"
        class="mb-0 mt-3"
        data-testid="transaction-dpe-kwhep"
      >
        {{ $t('house.transaction.dpeText', { number: certificateKwhep }) }}
      </p>

      <p
        v-if="['F', 'G'].includes(certificateRate)"
        class="mb-0 text-xs"
        data-testid="transaction-excessive-energy"
      >
        {{ $t('house.transaction.excessiveEnergyConsumption') }}
      </p>
    </div>
  </div>
</template>
