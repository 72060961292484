<script setup lang="ts">
withDefaults(
  defineProps<{
    gasRate: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G'
    gasKg: number | null
  }>(),
  {
    gasKg: null,
  },
)

const gasList = [
  { key: 'A', color: 'bg-[#E1DAE2]' },
  { key: 'B', color: 'bg-[#D2ADC5]' },
  { key: 'C', color: 'bg-[#BE88AA]' },
  { key: 'D', color: 'bg-[#B46A96]' },
  { key: 'E', color: 'bg-[#B24A83]' },
  { key: 'F', color: 'bg-[#A21E6E]' },
  { key: 'G', color: 'bg-[#940E69]' },
]
</script>

<template>
  <div>
    <div class="font-bold mb-4">GAS</div>
    <div class="w-min text-center">
      <ul class="flex items-center mb-0">
        <li
          v-for="gas in gasList"
          :key="gas.key"
          :class="[
            'flex items-center justify-center text-gray-700 font-bold',
            gas.color,
            {
              'w-[44px] h-[44px]': gas.key === gasRate,
              'w-[34px] h-[34px]': gas.key !== gasRate,
            },
          ]"
          data-testid="transaction-gas-square"
        >
          <template v-if="gas.key === gasRate">
            {{ gas.key }}
          </template>
        </li>
      </ul>
      <p v-if="gasKg" class="mb-0 mt-3" data-testid="transaction-gas-kg">
        {{ $t('house.transaction.gesText', { number: gasKg }) }}
      </p>
    </div>
  </div>
</template>
