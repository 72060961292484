<script setup lang="ts">
withDefaults(
  defineProps<{
    equipmentName: string
    text?: string
  }>(),
  {
    text: '',
  },
)

const { isDesktopOrTablet } = useBreakpoint()

const tooltipPosition = computed(() =>
  isDesktopOrTablet.value ? 'right' : 'bottom',
)
</script>

<template>
  <base-tooltip
    :position="tooltipPosition"
    class="inline-block cursor-pointer"
    content-class="!w-60"
    rounded
    show
    size="small"
    variant="white"
  >
    <template #element>
      <div class="flex items-center">
        {{ equipmentName }}
        <BaseIcon
          class="text-gray-400 cursor-pointer ml-2 pt-1"
          name="infoEmpty"
        />
      </div>
    </template>

    <template #text>
      <div v-if="text" data-testid="equipement-tooltip-text">
        {{ text }}
      </div>
    </template>
  </base-tooltip>
</template>
