<script setup lang="ts">
import { getPriceWithCurrency } from '~/helpers/priceHelper'
import type { SupportedCurrencies } from 'lc-services/types'
import type { HouseSaleInformations } from '~/types/house/types'

defineProps<{
  houseSaleInformations: HouseSaleInformations
}>()

defineEmits<{
  open: []
}>()

const { locale } = useI18n()

const getFormattedSalePrice = (
  salePrice: number,
  currency: SupportedCurrencies,
) =>
  getPriceWithCurrency({
    price: salePrice,
    currency,
    i18n: { locale },
  })
</script>

<template>
  <div class="fixed bottom-0 bg-white z-50 flex items-center w-full py-6 px-4">
    <div class="text-sm w-2/5">
      {{ $t('house.transaction.inquiry.salePrice') }}
      <div class="font-bold" data-testid="transaction-price">
        <template v-if="houseSaleInformations.displayPrices">
          {{
            getFormattedSalePrice(
              houseSaleInformations.price,
              houseSaleInformations.currency,
            )
          }}
        </template>
        <template v-else>
          {{ $t('house.transaction.uponRequest') }}
        </template>
      </div>
    </div>

    <base-button block-full color="secondary" @click="$emit('open')">
      {{ $t('house.book_button') }}
    </base-button>
  </div>
</template>
