<script setup lang="ts">
import { useStore } from 'vuex'
import type { HouseDetailsResponse } from 'lc-services/modules/search/usecases/GetHouseDetails/types'
import type {
  HouseDestination,
  HouseSaleInformations,
} from '~/types/house/types'

const props = withDefaults(
  defineProps<{
    initialStep?: number
    house: HouseDetailsResponse
    houseDestinations?: HouseDestination
    houseSaleInformations: HouseSaleInformations
    privateToken?: string
  }>(),
  {
    initialStep: 0,
    houseDestinations: () => ({}) as HouseDestination,
    privateToken: '',
  },
)

const store = useStore()
const { user, clearUser } = useUser()

const currentStep = ref(props.initialStep)

const setStep = (value: number) => {
  currentStep.value = value
}

const logout = () => {
  store.dispatch('auth/logout')
  clearUser()
}
</script>

<template>
  <div>
    <LazyHouseTransactionInquirySummary
      v-if="currentStep === 0"
      class="min-h-[calc(100vh-3rem)] md:min-h-max w-full md:p-7"
      :house="house"
      :house-destinations="houseDestinations"
      :house-sale-informations="houseSaleInformations"
      @step="setStep"
    />
    <LazyHouseTransactionInquiryForm
      v-if="currentStep === 1"
      class="min-h-[calc(100vh-3rem)] md:min-h-max w-full md:p-7"
      :logout="logout"
      :house="house"
      :house-destinations="houseDestinations"
      :private-token="privateToken"
      :user-infos="user"
      @step="setStep"
    />
    <LazyHouseTransactionInquirySuccess
      v-if="currentStep === 2"
      class="min-h-[calc(100vh-3rem)] md:min-h-max w-full md:p-7"
    />
  </div>
</template>
